import { CATEGORIES } from '@kijiji/category'

import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { type CoreEventArgs } from '@/lib/ga/utils/tracking'

type PopularOnKijijiStaticData = {
  intlKey: string
  tracking: CoreEventArgs
}

export const popularOnKijijiStaticData: PopularOnKijijiStaticData[] = [
  {
    intlKey: 'free',
    tracking: {
      action: GA_EVENT.CategorySelected,
      label: `catId=${CATEGORIES.GIVE_AWAY_CATEGORY_ID};btn=PopularKijiji`,
    },
  },
  { intlKey: 'wanted', tracking: { action: GA_EVENT.WantedSelected, label: 'btn=PopularKijiji' } },
]
