import { useTheme } from 'styled-components'

import { useBreakpointChange } from '@/hooks/useBreakpointChange'
import { StyledGptStickyHP } from '@/lib/ads/ad-slots/homepage/styled'
import { HOMEPAGE_GPT_ID } from '@/lib/ads/ads-config/homepage/homepageAdSlots'
import { triggerManualAdRefresh } from '@/lib/ads/utils/triggerManualAdRefresh'

export const GptStickyHP = () => {
  const { breakpoints } = useTheme()

  useBreakpointChange([breakpoints.medium], () => {
    triggerManualAdRefresh(HOMEPAGE_GPT_ID.GPT_STICKY)
  })

  return (
    <StyledGptStickyHP id={HOMEPAGE_GPT_ID.GPT_STICKY} data-testid={HOMEPAGE_GPT_ID.GPT_STICKY} />
  )
}
