import { type FC, useRef } from 'react'

import { MegaMenuCategoryTree } from '@/components/homepage/mega-menu/MegaMenuCategoryTree'
import { MegaMenuL1 } from '@/components/homepage/mega-menu/MegaMenuL1'
import { useActiveMegaMenu } from '@/components/homepage/mega-menu/useActiveMegaMenu'

export type MegaMenuProps = {
  locationId: number
}

export const MegaMenu: FC<MegaMenuProps> = ({ locationId }) => {
  const {
    activeL2Items,
    activeL3Items,
    brandedCategoryCampaign,
    closeMenu,
    handleClearTimeout,
    isCollapsedMenuView,
    isKeyboardNavigation,
    l1MenuItems,
    menuBarRef,
    onHover,
    openMenu,
    selectedL1Item,
    selectedL2Item,
  } = useActiveMegaMenu(locationId)

  const selectedL1Ref = useRef<HTMLAnchorElement>(null)

  return (
    <MegaMenuL1
      closeMenu={closeMenu}
      handleClearTimeout={handleClearTimeout}
      isCollapsedMenuView={isCollapsedMenuView}
      l1MenuItems={l1MenuItems}
      menuBarRef={menuBarRef}
      ref={selectedL1Ref}
      onHover={onHover}
      openMenu={openMenu}
      selectedL1Item={selectedL1Item}
    >
      {!!selectedL1Item?.id && (
        <MegaMenuCategoryTree
          activeL2Items={activeL2Items}
          activeL3Items={activeL3Items}
          brandedCategoryCampaign={brandedCategoryCampaign}
          closeMenu={closeMenu}
          handleClearTimeout={handleClearTimeout}
          isCollapsedMenuView={isCollapsedMenuView}
          openMenu={openMenu}
          selectedL1Item={selectedL1Item}
          selectedL2Item={selectedL2Item}
          ref={selectedL1Ref}
          isKeyboardNavigation={isKeyboardNavigation}
        />
      )}
    </MegaMenuL1>
  )
}
