import { type FC, type PropsWithChildren } from 'react'
import { AdvertisingProvider } from 'react-advertising'

import useCookiePreferences from '@/hooks/useCookiePreferences'
import { AdobeAudienceManager } from '@/lib/ads/components/adobe-audience-manager'
import { AdSenseScripts } from '@/lib/ads/components/adsense/AdSenseScripts'
import { AmazonTamScripts } from '@/lib/ads/components/amazon-tam/amazonTamScripts'
import { BlockthroughScripts } from '@/lib/ads/components/blockthrough/BlockthroughScripts'
import {
  createOptOutTargetingConfig,
  createTestTargetConfig,
} from '@/lib/ads/components/gpt/configGpt'
import { PrebidScripts } from '@/lib/ads/components/prebid/PrebidScripts'
import { type AdConfig, type GptTargeting } from '@/lib/ads/types/adConfig'

type AdsWrapperProps = PropsWithChildren & {
  gptConfig?: AdConfig
  gptTargetingValues?: GptTargeting[]
  prebidConfig?: { setupPrebid: () => void }[]
  skipAdsense?: boolean
  skipBlockthrough?: boolean
}

export const AdsWrapper: FC<AdsWrapperProps> = ({
  children,
  gptConfig,
  gptTargetingValues = [],
  prebidConfig,
  skipAdsense,
  skipBlockthrough,
}) => {
  const { hasUserOptedOutTargeting = false } = useCookiePreferences()

  /**
   * Append global targeting values
   * Those values should be applied on every ads configuration
   */
  const testEnvTarget = createTestTargetConfig()
  const targetingValues = [
    ...gptTargetingValues,
    createOptOutTargetingConfig(hasUserOptedOutTargeting),
  ]
  testEnvTarget && targetingValues.push(testEnvTarget)

  /**
   * We need to make React-Advertising wait for the GPT scripts to be loaded
   * Otherwise it will throw a window.googletag.cmd undefined error
   */
  return (
    <>
      <AdvertisingProvider config={gptConfig} plugins={prebidConfig}>
        {children}
      </AdvertisingProvider>

      <PrebidScripts />
      <AmazonTamScripts />
      <AdobeAudienceManager gpt={targetingValues} />

      {skipAdsense ? null : <AdSenseScripts />}
      {skipBlockthrough ? null : <BlockthroughScripts />}
    </>
  )
}
