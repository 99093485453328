import {
  type FeedResults,
  type HomeFeed,
  type UserLocation,
  useGetHomeFeedQuery,
} from '@kijiji/generated/graphql-types'
import { useSession } from 'next-auth/react'
import { useState } from 'react'

import { getClientType } from '@/components/homepage/domain/getClientType'
import { HOME_FEED_ITEMS_PER_ROW_DESKTOP } from '@/components/homepage/homepage-feed/styled'
import { ALL_CANADA_LOCATION_ID } from '@/constants/location'
import { isUserAuthenticated } from '@/domain/user'
import { mergeFetchMore } from '@/lib/apollo/mergeFetchMore'
import { isFeedResultsType } from '@/types/homepage'
import { sendToLogger } from '@/utils/sendToLogger'

/** Defines how many auto loads before button and AD shows up */
export const AUTO_LOAD_COUNT = 3
/** Defines how many items should be fetched each time */
export const HP_FEED_REQUEST_CHUNKS = 24
/** Defines how many rows before load button and AD shows up */
export const ROWS_PER_LOAD =
  (HP_FEED_REQUEST_CHUNKS / HOME_FEED_ITEMS_PER_ROW_DESKTOP) * AUTO_LOAD_COUNT

type HomepageInfiniteFeedProps = {
  correlationId: string
  locationArea?: UserLocation['area']
  locationId: number
  userAgent: string
}

type HomepageInfiniteFeedResponse = {
  feedPageInfo: HomeFeed['pageInfo']
  feedResults: FeedResults[]
  handleLoadMore: (loadMoreClick?: boolean) => Promise<void>
  loadingMore: boolean
  page: number
  shouldAutoFetchMore: boolean
  shouldShowHomepageFeed: boolean
  loadingFeed: boolean
}

export const useHomepageInfiniteFeed = ({
  correlationId,
  locationArea,
  locationId,
  userAgent,
}: HomepageInfiniteFeedProps): HomepageInfiniteFeedResponse => {
  const [loadingMore, setLoadingMore] = useState<boolean>(false)
  const [page, setPage] = useState<number>(1)
  const [shouldAutoFetchMore, setShouldAutoFetchMore] = useState<boolean>(true)

  const { data: userData, status } = useSession()

  const userId = parseInt(`${userData?.user.sub}`)
  const shouldSkipFeed =
    !isUserAuthenticated(status) || !userId || locationId === ALL_CANADA_LOCATION_ID

  const [loadingFeed, setLoadingFeed] = useState<boolean>(!shouldSkipFeed)

  const fetchVariables = {
    homePageMixGroup: 'KCAN-1708-C',
    input: {
      cid: correlationId,
      clientType: getClientType(userAgent),
      links: [],
      location: locationArea
        ? { latitude: locationArea.latitude, longitude: locationArea.longitude }
        : undefined,
      locationId,
      pagination: { pageNumber: 1, pageSize: HP_FEED_REQUEST_CHUNKS - 4 },
      userId,
    },
  }

  const { data, fetchMore } = useGetHomeFeedQuery({
    fetchPolicy: 'network-only',
    ssr: false,
    skip: shouldSkipFeed,
    variables: fetchVariables,
    onCompleted: () => loadingFeed && setLoadingFeed(false),
    onError: (err) => {
      sendToLogger(err, { fingerprint: ['useHomepageInfiniteFeed'] })
      setLoadingFeed(false)
    },
  })

  const handleFetchMore = async (loadMoreClick?: boolean) => {
    /**
     * It should not trigger a re-fetch if:
     * - User didn't manually clicked to load more items AND it shouldn't automatically re-fetch on scroll
     * - If we reached the last page of data
     */
    if ((!shouldAutoFetchMore && !loadMoreClick) || !data?.homeFeed?.pageInfo?.hasNextPage) {
      return
    }

    if (loadMoreClick) {
      /** Should reset the value to allow auto fetch more again if user clicked on "load more" button */
      setShouldAutoFetchMore(true)
    }

    setLoadingMore(true)

    /** Get next chunk of data */
    const newPage = page + 1
    await fetchMore({
      variables: {
        ...fetchVariables,
        input: {
          ...fetchVariables.input,
          links: data?.homeFeed?.pageInfo?.links || [],
          /**
           * We are decreasing 4 items on the page-size request to match production
           * It is requesting 20 and expecting to receive 24 from the BE.
           *
           * We will revisit the limit not being respected by the BE once NWA is live in prod.
           */
          pagination: { pageNumber: page, pageSize: HP_FEED_REQUEST_CHUNKS - 4 },
        },
      },
      /** Merge items on each fetch */
      updateQuery(prevResult, { fetchMoreResult }) {
        const prevItems = prevResult.homeFeed?.results
        const newItems = fetchMoreResult.homeFeed?.results

        const mergedItems = mergeFetchMore<FeedResults>({
          prevItems: isFeedResultsType(prevItems) ? prevItems : [],
          fetchMoreItems: isFeedResultsType(newItems) ? newItems : [],
        })

        const isLastPage = !!fetchMoreResult.homeFeed?.pageInfo?.hasNextPage
        !isLastPage && setPage((prev) => prev + 1)

        setLoadingMore(false)

        return {
          __typename: 'Query',
          homeFeed: {
            __typename: 'HomeFeed',
            pageInfo: fetchMoreResult.homeFeed?.pageInfo,
            results: mergedItems,
          },
        }
      },
    })

    setPage(newPage)

    /**
     * Logic to verify if should continue auto fetching
     * should not auto-fetch every 3 re-fetches
     * */
    if (newPage % AUTO_LOAD_COUNT === 0) {
      setShouldAutoFetchMore(false)
    }
  }

  return {
    feedPageInfo: data?.homeFeed?.pageInfo,
    feedResults:
      data?.homeFeed?.results && isFeedResultsType(data.homeFeed.results)
        ? data.homeFeed.results
        : [],
    handleLoadMore: handleFetchMore,
    loadingFeed,
    loadingMore,
    page,
    shouldAutoFetchMore,
    shouldShowHomepageFeed: !shouldSkipFeed && !!data?.homeFeed?.results?.length,
  }
}
