import { type GetMenuPrefetchQuery } from '@kijiji/generated/graphql-types'
import dynamic from 'next/dynamic'
import { useTranslation } from 'next-i18next'
import {
  type FC,
  type KeyboardEvent,
  type MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import {
  MegaMenuL1Link,
  MegaMenuL1MoreItems,
  MegaMenuL1MoreMenu,
} from '@/components/homepage/mega-menu/styled'

const IconEllipsisIcon = dynamic(() => import('@kijiji/icons/src/icons/IconEllipsis'), {
  ssr: false,
})

export type MegaMenuMoreEllipsisProps = {
  moreMenuItems: GetMenuPrefetchQuery['menuPrefetch']
  parentOnKeyDownHandler?: (event: KeyboardEvent<HTMLLIElement>) => void
}

export const MegaMenuMoreEllipsis: FC<MegaMenuMoreEllipsisProps> = ({
  moreMenuItems,
  parentOnKeyDownHandler,
}) => {
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState<boolean>(false)
  const { t } = useTranslation('home')
  const firstMenuItemRef = useRef<HTMLAnchorElement>(null)
  const isKeyboardNavigation = useRef<boolean>(false)

  useEffect(() => {
    /**
     * there are always 2 items in the more menu
     * we're using CSS to control whether the 1st item is visible
     * so, if the 1st item is not visible, we want to focus on the 2nd item
     */
    if (isMoreMenuOpen && isKeyboardNavigation.current && firstMenuItemRef.current?.parentElement) {
      const elementStyle = window.getComputedStyle(firstMenuItemRef.current.parentElement)

      const displayProperty = elementStyle.getPropertyValue('display')
      const nextL1Item =
        firstMenuItemRef.current?.parentElement.nextElementSibling?.firstElementChild
      if (displayProperty === 'none' && nextL1Item instanceof HTMLElement) {
        nextL1Item.focus()
      } else {
        firstMenuItemRef.current?.focus()
      }
    }
  }, [isMoreMenuOpen, isKeyboardNavigation])

  /**
   * The open/close state of the more-menu could be accomplished with CSS ":hover"
   * selector only, however to ensure this component has proper accessibility aria attributes
   * We are manually controlling its behaviour
   */
  const openMoreMenu = useCallback((isKeyboardEvent: boolean | MouseEvent) => {
    typeof isKeyboardEvent === 'boolean' && isKeyboardEvent
      ? (isKeyboardNavigation.current = true)
      : (isKeyboardNavigation.current = false)

    setIsMoreMenuOpen(true)
  }, [])

  const closeMoreMenu = useCallback(() => {
    setIsMoreMenuOpen(false)
  }, [])

  const onKeyDown = (event: KeyboardEvent<HTMLLIElement>) => {
    const activeLink = global.document && global.document.activeElement
    const activeListItem = activeLink?.parentElement
    const nextListItem = activeListItem?.nextElementSibling
    const prevListItem = activeListItem?.previousElementSibling

    switch (event.key) {
      case 'Enter':
      case ' ': {
        openMoreMenu(true)
        break
      }
      case 'Tab': {
        /**
         * there are always 2 items in the more menu
         * we're using CSS to control whether the 1st item is visible
         */
        const isFirstItemDisplayed =
          prevListItem instanceof HTMLElement
            ? window.getComputedStyle(prevListItem).getPropertyValue('display') !== 'none'
            : false

        if (!event.shiftKey && activeListItem && !nextListItem) {
          closeMoreMenu()
        } else if (event.shiftKey && activeListItem && !isFirstItemDisplayed) {
          closeMoreMenu()
        }
        break
      }
      case 'Escape': {
        closeMoreMenu()
        break
      }

      default: {
        break
      }
    }

    if (parentOnKeyDownHandler) {
      parentOnKeyDownHandler(event)
    }
  }

  if (!moreMenuItems?.length) return null

  return (
    <MegaMenuL1MoreItems
      data-testid="more-menu-li"
      id="cat-menu-more"
      onMouseEnter={openMoreMenu}
      onMouseLeave={closeMoreMenu}
      onKeyDown={onKeyDown}
      role="none"
    >
      <MegaMenuL1Link
        aria-controls="cat-menu-group-more"
        aria-expanded={isMoreMenuOpen}
        aria-haspopup="true"
        aria-label={t('mega_menu.nav.more_menu')}
        as="button"
        data-cat-id="more"
        id="cat-menu-item-more"
        role="menuitem"
      >
        <IconEllipsisIcon aria-hidden />
      </MegaMenuL1Link>

      <MegaMenuL1MoreMenu
        aria-expanded={isMoreMenuOpen}
        aria-hidden
        aria-labelledby="cat-menu-item-more"
        data-testid="more-menu-list"
        id="cat-menu-group-more"
      >
        {moreMenuItems.map((item, index) => {
          if (!item) return null

          return (
            <li key={`more-menu-${item.categoryName}-${item.id}`}>
              <MegaMenuL1Link
                data-testid="more-menu-items"
                href={item.seoUrl}
                role="menuitem"
                ref={index === 0 ? firstMenuItemRef : null}
              >
                {item.categoryName}
              </MegaMenuL1Link>
            </li>
          )
        })}
      </MegaMenuL1MoreMenu>
    </MegaMenuL1MoreItems>
  )
}
