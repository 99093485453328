import { HideAtOrLargerHP } from '@/components/homepage/shared/ShowAtOrLargerHP'
import { LEGACY_HP_LG_BREAKPOINT } from '@/constants/pageSettings'
import { AdChoicesLink } from '@/lib/ads/ad-slots/homepage/AdChoicesLink'
import {
  GptRightrailMarketingWrapper,
  StyledGptRightrailMarketing,
} from '@/lib/ads/ad-slots/homepage/styled'
import { HOMEPAGE_GPT_ID } from '@/lib/ads/ads-config/homepage/homepageAdSlots'

export const GptRightrailMarketing = () => {
  return (
    <GptRightrailMarketingWrapper>
      <HideAtOrLargerHP breakpoint={LEGACY_HP_LG_BREAKPOINT}>
        <AdChoicesLink />
      </HideAtOrLargerHP>

      <StyledGptRightrailMarketing
        id={HOMEPAGE_GPT_ID.RIGHTRAIL_MARKETING}
        data-testid={HOMEPAGE_GPT_ID.RIGHTRAIL_MARKETING}
      />
    </GptRightrailMarketingWrapper>
  )
}
