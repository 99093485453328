import dynamic from 'next/dynamic'
import { useTranslation } from 'next-i18next'
import { type FC, Fragment } from 'react'
import { useTheme } from 'styled-components'

import { popularOnKijijiStaticData } from '@/components/homepage/popular-on-kijiji/popularOnKijijiStaticData'
import {
  PopularOnKijijiContainer,
  PopularOnKijijiLink,
  PopularOnKijijiList,
} from '@/components/homepage/popular-on-kijiji/styled'
import { type GetSeoLocationResponse } from '@/components/homepage/useHomepageGetSeoUrl'
import { attachRadialLocationParams } from '@/domain/location/attachRadialLocationParams'
import { useGetLocation } from '@/hooks/location/useGetLocation'
import { trackEvent } from '@/lib/ga'
import { type GaEvent } from '@/lib/ga/constants/gaEvent'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { Spacing } from '@/ui/atoms/spacing'

const RightArrowIcon = dynamic(() => import('@kijiji/icons/src/icons/Chevron'), { ssr: false })

export type PopularOnKijijiProps = {
  /**
   * Gets the location SEO url values
   */
  seoLocation: GetSeoLocationResponse
}

export const PopularOnKijiji: FC<PopularOnKijijiProps> = ({ seoLocation }) => {
  const { location } = useGetLocation()
  const { t } = useTranslation('home')
  const { colors, spacing } = useTheme()

  const handleTracking = (action: GaEvent, label?: string) => {
    trackEvent({ action, label })
  }

  return (
    <PopularOnKijijiContainer $isFullWidth data-testid="popular-kijiji-home">
      <Spacing mBottom={spacing.large}>
        <HeadlineText as="h2" color={colors.grey.primary} size="medium" weight="regular">
          {t('popular_on_kijiji.title')}
        </HeadlineText>
      </Spacing>

      <PopularOnKijijiList>
        {popularOnKijijiStaticData.map((item, index) => {
          const href = attachRadialLocationParams(
            t(`popular_on_kijiji.${item.intlKey}.link`, {
              locationId: seoLocation.id,
              seoLocation: seoLocation.seoCurrentName,
            }),
            location
          )

          return (
            <Fragment key={`hp-popular-on-kijiji-${index}`}>
              <li>
                <PopularOnKijijiLink
                  href={href}
                  onClick={() => handleTracking(item.tracking.action, item.tracking.label)}
                >
                  {t(`popular_on_kijiji.${item.intlKey}.label`)}

                  <RightArrowIcon aria-hidden />
                </PopularOnKijijiLink>
              </li>
            </Fragment>
          )
        })}
      </PopularOnKijijiList>
    </PopularOnKijijiContainer>
  )
}
