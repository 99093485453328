import { useTheme } from 'styled-components'

import { useBreakpointChange } from '@/hooks/useBreakpointChange'
import { StyledGptRightrailBottom } from '@/lib/ads/ad-slots/homepage/styled'
import { HOMEPAGE_GPT_ID } from '@/lib/ads/ads-config/homepage/homepageAdSlots'
import { triggerManualAdRefresh } from '@/lib/ads/utils/triggerManualAdRefresh'

export const GptRightrailBottom = () => {
  const { breakpoints } = useTheme()

  useBreakpointChange([breakpoints.large, breakpoints.medium], () => {
    triggerManualAdRefresh(HOMEPAGE_GPT_ID.RIGHTRAIL_BOTTOM)
  })

  return (
    <StyledGptRightrailBottom
      id={HOMEPAGE_GPT_ID.RIGHTRAIL_BOTTOM}
      data-testid={HOMEPAGE_GPT_ID.RIGHTRAIL_BOTTOM}
    />
  )
}
