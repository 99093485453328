import dynamic from 'next/dynamic'
import { useTranslation } from 'next-i18next'

import {
  AD_CHOICES_ICON_SIZE,
  AdChoicesIconContainer,
  StyledAdChoicesCustomLink,
} from '@/lib/ads/ad-slots/homepage/styled'

const AdChoicesLogoIcon = dynamic(() => import('@kijiji/icons/src/icons/AdChoicesLogo'), {
  ssr: false,
})

export const AdChoicesLink = () => {
  const { t } = useTranslation('common')

  return (
    <StyledAdChoicesCustomLink
      href={t('routes:external.ad_choices.href')}
      size="small"
      variant="secondary"
    >
      {t('ad_choices.label')}
      <AdChoicesIconContainer>
        <AdChoicesLogoIcon
          width={AD_CHOICES_ICON_SIZE}
          height={AD_CHOICES_ICON_SIZE}
          data-testid="ad-choices-logo-icon"
        />
      </AdChoicesIconContainer>
    </StyledAdChoicesCustomLink>
  )
}
