import styled from 'styled-components'

import { MEDIA_QUERY_HP_LARGE_DESKTOP } from '@/components/homepage/styled'
import { LinkCustom } from '@/components/shared/link-custom/LinkCustom'
import { Panel } from '@/ui/atoms/panel'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const PopularOnKijijiContainer = styled(Panel)(
  ({ theme }) => `
  margin-bottom: ${theme.spacing.husky};
  padding-bottom: 4.5rem;
  width: 100%;

  ${MEDIA_QUERIES(theme).medium}{
    margin-bottom: 0;
  }

  ${MEDIA_QUERY_HP_LARGE_DESKTOP} {
    padding-bottom: ${theme.spacing.large};
  }
`
)

export const PopularOnKijijiList = styled.ul(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.default};

  li:not(:last-child)  {
    padding-bottom: ${theme.spacing.default};
    border-bottom: .1rem solid ${theme.colors.grey.light4};
  }
`
)

export const PopularOnKijijiLink = styled(LinkCustom)(
  ({ theme }) => `
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  color: ${theme.colors.blue.primary};

  & svg {
    height: auto;
    width: 1.8rem;
  }

  &:hover span {
    text-decoration: 'underline';
  }
`
)
