import {
  RecommendationProcessor,
  useGetFeedRecommendationsQuery,
} from '@kijiji/generated/graphql-types'
import truncate from 'lodash/truncate'
import { useSession } from 'next-auth/react'
import { useTranslation } from 'next-i18next'
import { type FC, type ReactNode } from 'react'
import { useTheme } from 'styled-components'

import { GalleryHeaderWrapper } from '@/components/shared/gallery/GalleryHeaderWrapper'
import { GalleryWrapper } from '@/components/shared/gallery/GalleryWrapper'
import { LegacyCarousel } from '@/components/shared/legacy-carousel/LegacyCarousel'
import { LinkCustom } from '@/components/shared/link-custom/LinkCustom'
import { VerticalListingCard } from '@/components/shared/vertical-listing-card/VerticalListingCard'
import { getGaAdType } from '@/domain/ga/getGaAdType'
import { isUserAuthenticated } from '@/domain/user'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { Flex } from '@/ui/atoms/flex'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { Spacing } from '@/ui/atoms/spacing'

export type SimilarToFavouritesGalleryProps = {
  locationId: number
}

export const SimilarToFavouritesGallery: FC<SimilarToFavouritesGalleryProps> = ({ locationId }) => {
  const { status, data: userData } = useSession()
  const { colors, spacing } = useTheme()
  const { t } = useTranslation('home')

  const userId = parseInt(`${userData?.user.sub}`)

  /**
   * There is no pagination or fetchMore defined for this carousel
   */
  const { data: feedData } = useGetFeedRecommendationsQuery({
    skip: !isUserAuthenticated(status) || !userId,
    ssr: false,
    variables: {
      input: { processor: RecommendationProcessor.SimilarToFavourites, userId, locationId },
    },
    onCompleted: ({ feedRecommendations }) => {
      if (!feedRecommendations?.ads?.length || feedRecommendations.ads.length < 5) return

      /**
       * Impression tracking on section load
       * It has been flagged to the FAST team the weird pattern on the label:
       * - "block" is referring to VIP
       * - "position" is sending a hard coded text instead of a dynamic position value
       *  */
      trackEvent({
        action: GA_EVENT.ResultsImpressions,
        label: `block=SimilarToFavouriteVIP;position=$position;CTA=carousel;item=${feedRecommendations?.seedId};`,
      })
    },
  })

  const handleSeeTracking = () => {
    trackEvent({
      action: GA_EVENT.BlockTitleClick,
      label: `block=SimilarToFavouriteVIP;item=${feedData?.feedRecommendations?.seedId};CTA=carousel`,
    })
  }

  const slides: ReactNode[] =
    feedData?.feedRecommendations?.ads?.map((item, index) => {
      if (!item) return null

      const handleListingClickTracking = () => {
        trackEvent({
          action: GA_EVENT.RecommendationAdClick,
          label: `block=SimilarToFavouriteVIP;item=${feedData.feedRecommendations?.seedId};AdID=${
            item.id
          };adIndex=${index + 1};CTA=carousel;type=${getGaAdType(item.type)}`,
        })
      }

      return (
        <VerticalListingCard
          allowFavourite
          key={`hp-similar-feed-${item.id}`}
          listingId={item.id}
          listingImage={{ alt: '', src: item.imageUrls?.[0] }}
          listingImageSize="regular"
          handleClick={handleListingClickTracking}
          price={item.price?.amount}
          seoUrl={item.seoUrl}
          title={item.title}
        />
      )
    }) || []

  const hasEnoughAds = slides.length >= 5
  if (!hasEnoughAds) return null

  const truncatedLink = truncate(feedData?.feedRecommendations?.description, { length: 35 })

  return (
    <GalleryWrapper data-testid="hp-similar-to-favourites-section">
      <Spacing mBottom={spacing.defaultSmall} data-testid="hp-gallery-header">
        <GalleryHeaderWrapper justifyContent="space-between" alignItems="center">
          <Flex alignItems="center" flexWrap="wrap">
            <HeadlineText as="h2" color={colors.grey.primary} size="medium" weight="regular">
              {t('similar_to_favourites.title')}{' '}
              {feedData?.feedRecommendations ? (
                <LinkCustom
                  href={feedData.feedRecommendations?.seoUrl}
                  onClick={handleSeeTracking}
                  target="_blank"
                  variant="secondary"
                >
                  &quot;{truncatedLink}&quot;
                </LinkCustom>
              ) : null}
            </HeadlineText>
          </Flex>
        </GalleryHeaderWrapper>
      </Spacing>

      <Flex>
        <LegacyCarousel
          name="hp-similar-to-favourites-carousel"
          shouldShowArrows={[true, false, false]}
          slides={slides}
          slidesToScroll={[2.5, 1.5, 1.5]}
          slidesToShow={[5, 4.2, 2.2]}
          useLegacyLgDesktopBreakpoint
        />
      </Flex>
    </GalleryWrapper>
  )
}
