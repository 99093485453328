import { type ThemeProps } from '@kijiji/theme'
import styled from 'styled-components'

import { MEDIA_QUERY_HP_LARGE_DESKTOP } from '@/components/homepage/styled'
import { LinkCustom } from '@/components/shared/link-custom/LinkCustom'
import { zIndexRegistry } from '@/constants/zIndexRegistry'
import { Flex } from '@/ui/atoms/flex'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { TRANSITION_STYLE } from '@/ui/constants/commonStyles'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'
import { styleFromTypography } from '@/ui/helpers/styleFromTypography'

const MEDIA_QUERY_NAVIGATION_XLARGE_DESKTOP = `@media screen and (min-width: 1200px)`
const MEDIA_QUERY_NAVIGATION_MEDIUM_DESKTOP = `@media screen and (min-width: 975px)`

/**
 * Constant created to centralize the breakpoint where the desktop
 * version of the submenu switches to the mobile version.
 * */
const MEDIA_QUERY_MENU_BREAK = (theme: ThemeProps) => MEDIA_QUERIES(theme).large

export const MegaMenuL1IconsContainer = styled(Flex)`
  display: flex;

  ${MEDIA_QUERY_NAVIGATION_MEDIUM_DESKTOP} {
    display: none;
  }
`

export const MegaMenuL1Nav = styled.nav(
  ({ theme }) => `
  align-items: center;
  background-color: ${theme.colors.white};
  border-bottom-width: 0;
  display: flex;
  justify-content: center;
  max-width: 100%;
  position: relative;
  width: 100%;
`
)

export const MegaMenuL1Container = styled.div`
  width: 100%;
`

export const MegaMenuL1IconsListContainer = styled.div(
  ({ theme }) => `
  display: flex;
  margin-right: -${theme.spacing.default};

  ${MEDIA_QUERIES(theme).medium} {
    display: none;
  }
`
)

export const MegaMenuL1List = styled.ul(
  ({ theme }) => ` 
  display: none;
  
  // Icon menu before it becomes a carousel
  ${MEDIA_QUERIES(theme).medium}{
    align-items: center;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    justify-content: space-between;
    padding: 0 4rem;
    
    & > li:nth-last-of-type(3),
    & > li:nth-last-of-type(2) {
      display: flex;
    }
  }

  // Inline menu list with ellipsis
  ${MEDIA_QUERY_NAVIGATION_MEDIUM_DESKTOP} {
    display: flex;
    gap: ${theme.spacing.husky};
    justify-content: flex-start;
    padding: 0;

    & > li:nth-last-of-type(3),
    & > li:nth-last-of-type(2) {
      display: none;
    }
  }

  ${MEDIA_QUERY_HP_LARGE_DESKTOP} {
    & > li:nth-last-of-type(3) {
      display: block;
    }
  }

  ${MEDIA_QUERY_NAVIGATION_XLARGE_DESKTOP} {
    & > li:nth-last-of-type(2) {
      display: block;
    }
  }
`
)

const activeL1Border = (theme: ThemeProps) => `
  border-bottom: .3rem solid ${theme.colors.green.primary};  
`

export const MegaMenuL1ListItem = styled.li<{ isActive?: boolean }>(
  ({ theme, isActive }) => `
  ${TRANSITION_STYLE}
  border-bottom: .3rem solid ${theme.colors.white};
  height: 100%;

  /** Desktop Menu */
  ${MEDIA_QUERY_NAVIGATION_MEDIUM_DESKTOP} {
    ${isActive ? activeL1Border(theme) : ''}

    &:hover,
    &:focus,
    &:active {
      ${activeL1Border(theme)}
    }
  }
`
)

export const MegaMenuMobileL1IconContainer = styled.div(
  ({ theme }) => `
  background-color: ${theme.colors.purple.light5};
  border-radius: ${theme.borderRadius.rounded};
  display: block;
  height: 4.8rem;
  justify-self: center;
  width: 4.8rem;

  & > svg {
    justify-self: center;
    color: ${theme.colors.purple.light5};
    height: 4.8rem;
    width: 4.8rem;
  }

  ${MEDIA_QUERY_NAVIGATION_MEDIUM_DESKTOP} {
    display: none;
  }
`
)

// eslint-disable-next-line @kijiji/prefer-link-custom
export const MegaMenuL1Link = styled.a(
  ({ theme }) => `
  ${styleFromTypography(theme.typography.body.xSmall)};
  align-items: flex-start;
  color: ${theme.colors.purple.primary};
  display: grid;
  height: 100%;
  max-width: 7rem;
  padding: ${theme.spacing.defaultSmall} 0 ${theme.spacing.defaultSmall};
  text-align: center;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  & > span {
    align-self: center;
    margin-top: ${theme.spacing.mini};
  }

  ${MEDIA_QUERIES(theme).medium} {
    max-width: 8.2rem;
  }

  @media screen and (min-width: 930px){
    max-width: 9rem;

    & > span {
      margin-top: ${theme.spacing.defaultSmall};
    }
  }

  ${MEDIA_QUERY_NAVIGATION_MEDIUM_DESKTOP} {
    ${styleFromTypography(theme.typography.body.small)};
    font-size: 1.5rem;
    max-width: unset;
    padding-bottom: ${theme.spacing.default};

    & svg {
      display: none;
    }
  }
`
)

export const MegaMenuL1MoreItems = styled(MegaMenuL1ListItem)(
  ({ theme }) => `
  display: none;
  padding: ${theme.spacing.defaultSmall} 0;

  & > button,
  & svg {
    color: ${theme.colors.purple.primary};
    display: block;
    height: .4rem;
    width: 2rem;
  }

  & ul {
    display: none;
  }

  & button[aria-expanded='true'] + ul {
    display: block;
  }

  &:hover {
    border-bottom: .3rem solid ${theme.colors.white};
  }

  & a {
    padding: 0;
  }

  ${MEDIA_QUERY_NAVIGATION_MEDIUM_DESKTOP} {
    align-items: center;
    display: flex;
    height: 3rem;
    position: relative;
  }

  ${MEDIA_QUERY_NAVIGATION_XLARGE_DESKTOP} {
    display: none;
  }
`
)

const moreMenuArrow = (theme: ThemeProps) => `
  &::before {
    content: "";
    border: 1rem solid transparent;
    border-bottom-color: ${theme.colors.grey.light3};
    height: 0;
    margin-left: -1rem;
    pointer-events: none;
    position: absolute;
    right: 2rem;
    top: -2rem;
    width: 0;
  }

  &::after {
    content: "";
    border: 1rem solid transparent;
    border-bottom-color: ${theme.colors.white};
    height: 0;
    margin-left: -1rem;
    pointer-events: none;
    position: absolute;
    right: 2rem;
    top: -1.8rem;
    width: 0;
  }
`

export const MegaMenuL1MoreMenu = styled.ul(
  ({ theme }) => `
  ${moreMenuArrow(theme)}
  background-color: ${theme.colors.white};
  border-radius: ${theme.borderRadius.small};
  border: .1rem solid ${theme.colors.grey.light3};
  box-shadow: 0 .2rem .5rem -.2rem rgba(0, 0, 0, 0.1);
  min-width: 14rem;
  padding: .5rem;
  position: absolute;
  right: -2rem;
  top: 3rem;
  z-index: ${zIndexRegistry.MAIN_MENU};


  & > li {
    padding: .5rem .5rem;

    & > a {
      ${TRANSITION_STYLE}
      text-align: left;
      &:hover {
        color: ${theme.colors.blue.primary};
      }
    }

    &:nth-last-of-type(3),
    &:nth-last-of-type(2),
    &:nth-last-of-type(1) {
      display: block;
    }
  
  
    // Hide list items based on breakpoints
    ${MEDIA_QUERY_MENU_BREAK(theme)} {
      &:nth-last-of-type(3) {
        display: none;
      }
    }
  
    ${MEDIA_QUERY_HP_LARGE_DESKTOP} {
      &:nth-last-of-type(2) {
        display: none;
      }
    }
  
    ${MEDIA_QUERY_NAVIGATION_XLARGE_DESKTOP} {
      &:nth-last-of-type(1) {
        display: none;
      }
    }
  }

  & > li > a {
    display: block;
    width: 100%;

    &:hover {
      text-decoration: underline;
    }
  }
`
)

/**
 * Should show a limited number of menu items on smaller breakpoints than the carousel accepts
 * This is an exception to the carousel rules as it won't show slides, but menu items
 * This logic will ensure the menu always displays half of the last item, without having to manipulate the DOM with JS
 */
const getSlideWidth = (slidesToShow?: number) =>
  !slidesToShow ? 'unset' : `calc(${(100 / slidesToShow).toFixed(2)}% - 0.7rem)`
export const megaMenuCustomSlideStyle = `
  @media screen and (min-width: 360px){
    width: ${getSlideWidth(4.4)};
  }

  @media screen and (min-width: 500px){
    width: ${getSlideWidth(6.5)};
  }
`

/** SUBMENU */

export const MegaMenuCategoryTreeWrapper = styled.div(
  ({ theme }) => `
  background-color: ${theme.colors.white};
  border-bottom: .1rem solid rgba(0,0,0,.05);
  display: none;
  height: 49.8rem;
  position: absolute;
  width: calc(100% - 2rem);
  z-index: ${zIndexRegistry.MAIN_MENU};

  ${MEDIA_QUERY_NAVIGATION_MEDIUM_DESKTOP} {
    display: flex;
  }
`
)

export const SubCategoryL2Group = styled.div(
  ({ theme }) => `
  border-right: .1rem solid ${theme.colors.grey.light4};
  flex-basis: 35rem;
  height: 100%;
  max-width: 35rem;
  min-width: 32rem;
  overflow-y: auto;
  padding: 0 1rem 0 2rem;
`
)

export const SubCategoryHiddenTitle = styled(HeadlineText)`
  display: none;
`

const subtreeCategoryMenu = `
  flex-wrap: wrap;
  padding: 0 2rem;
  width: fit-content;
  height: 100%;
`

export const SubCategoryMenu = styled.ul<{ isSubTree?: boolean }>(
  ({ theme, isSubTree }) => `
  display: flex;
  flex-direction: column;
  padding: 0;

  ${isSubTree ? subtreeCategoryMenu : ''}

  /** See All Link */
  & > li:first-child > a {
    color: ${theme.colors.blue.primary};
    font-size: 1.5rem;
    padding: 0 0 ${theme.spacing.default};

    &:hover {
      color: ${theme.colors.blue.dark1};
      text-decoration: underline;
    }
  }

  & > li:last-child > a {
    border-bottom: none;
  }

  /** DESKTOP MENU */
  ${MEDIA_QUERY_MENU_BREAK(theme)} {
    padding: ${theme.spacing.default} 0 0;

    & > li:first-child > a {
      padding: ${isSubTree ? '0 0 1rem 0' : '1rem 0'};
    }
  }
`
)

export const MenuImageInsetShadow = styled.div`
  box-shadow: inset 0 2px 5px -2px rgba(0, 0, 0, 0.1);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
`

const subTreeListItemHover = (theme: ThemeProps) => `
  & a {
    text-decoration: underline; 
    color: ${theme.colors.blue.primary};
  }
`
export const SubCategoryListItem = styled.li<{ isActive?: boolean; isSubTree?: boolean }>(
  ({ theme, isActive, isSubTree }) => `
  padding: 0;
  ${isActive ? `background-color: ${theme.colors.purple.light5};` : ''}

  &:hover {
    ${isSubTree && subTreeListItemHover(theme)}
  }

  /** DESKTOP MENU */
  ${MEDIA_QUERY_MENU_BREAK(theme)}{
    padding: 0 ${theme.spacing.large};

    &:hover {
      ${!isSubTree ? `background-color: ${theme.colors.grey.light4};` : subTreeListItemHover(theme)}
    }
  }
`
)

const subTreeLink = `
  border-bottom: none;
  padding: 0;
  padding: 1rem 0;
`

export const SubCategoryLink = styled(LinkCustom)<{ $isSubTree?: boolean }>(
  ({ theme, $isSubTree }) => `
  ${TRANSITION_STYLE}
  ${styleFromTypography(theme.typography.body.medium)}
  align-items: center;
  border-bottom: .1rem solid ${theme.colors.grey.light4};
  color: ${theme.colors.grey.primary};
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  text-decoration: none;
  width: 100%;

  &:hover {
    text-decoration: none;
  }

  ${$isSubTree ? subTreeLink : ''}

  & span {
    height: 1.8rem;

    & svg {
      color: ${theme.colors.grey.primary};
      height: 100%;
      width: auto;
    }
  }

  /** DESKTOP MENU */
  ${MEDIA_QUERY_MENU_BREAK(theme)}{
    padding: ${$isSubTree ? `${theme.spacing.mini} 0` : `${theme.spacing.defaultSmall} 0`};
  }
`
)

export const SubCategoryXPromoLink = styled(SubCategoryLink)(
  ({ theme }) => `
  align-items: center;
  justify-content: flex-start;

  & svg {
    height: 1.5rem;
    margin-left: ${theme.spacing.defaultSmall};
    margin-top: .3rem;
    width: 7.5rem;
  }
`
)

export const SubCategoryCampaignLink = styled(SubCategoryLink)`
  align-items: center;
  justify-content: flex-start;
`

export const SubCategoryCampaignImg = styled.img(
  ({ theme }) => `
  height: 1.8rem;
  margin-right: ${theme.spacing.defaultSmall};
`
)

const subCategoryTwoOrMoreColumns = `
  background-color: hsla(0, 0%, 100%, 0.9);
  box-shadow: inset 0 0.2rem 0.5rem -0.2rem rgba(0, 0, 0, 0.1);
`

export const SubCategoryL3Container = styled.div<{ twoOrMoreColumns?: boolean }>(
  ({ twoOrMoreColumns }) => `
  ${twoOrMoreColumns ? subCategoryTwoOrMoreColumns : ''}
  height: 100%;
  padding: 1.5rem 0;
  position: relative;
  width: 100%;
`
)

/** MODAL */

export const MegaMenuModalPanel = styled.div(
  ({ theme }) => `
    border-radius: ${theme.borderRadius.small};
    display: flex;
    flex-direction: column;
    overflow: scroll;
    position: relative;
  `
)

export const MegaMenuModalHeader = styled.div(
  ({ theme }) => `
  background-color: ${theme.colors.purple.light5};
  height: 6rem;
  width: 100%;
`
)

// eslint-disable-next-line @kijiji/prefer-button-component
export const MegaMenuModalHeaderBackButton = styled.button(
  ({ theme }) => `
  ${styleFromTypography(theme.typography.body.medium)}
  color: ${theme.colors.purple.primary};
  display: flex;
  gap: ${theme.spacing.mini};
  padding: ${theme.spacing.default};
  align-items: center;

  & span {
    height: 2rem;
    width: 2rem;

    & svg {
      color: ${theme.colors.purple.primary};
      height: auto;
      width: 2rem;
      transform: rotate(180deg);
    }
  }
`
)

/** TRENDING CATEGORIES */
export const TrendingCategoriesLink = styled(LinkCustom)(
  ({ theme }) => `
  ${styleFromTypography(theme.typography.body.xSmall)}
  align-items: center;
  color: ${theme.colors.grey.light1};
  display: none;
  flex-direction: column;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    text-decoration: underline;
  }

  &:active {
    & > div {
      color: ${theme.colors.yellow.primary};
      border-color: ${theme.colors.purple.primary};
      background-color: ${theme.colors.purple.primary};
    }
  }

  /** It should only be displayed on the Desktop menu */
  ${MEDIA_QUERY_NAVIGATION_MEDIUM_DESKTOP} {
    display: flex;
  }
`
)

export const TrendingCategoriesBlock = styled.div(
  ({ theme }) => `
    background-color: ${theme.colors.yellow.primary};
    border-radius: ${theme.borderRadius.small};
    color: ${theme.colors.purple.primary};
    margin: 0 auto .5rem;
    padding: ${theme.spacing.mini} ${theme.spacing.defaultSmall};
    width: fit-content;
`
)
