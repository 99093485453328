import { StyledGptLeaderBase } from '@/lib/ads/ad-slots/homepage/styled'
import { HOMEPAGE_GPT_ID } from '@/lib/ads/ads-config/homepage/homepageAdSlots'

export const GptLeaderBase = () => {
  return (
    <StyledGptLeaderBase
      id={HOMEPAGE_GPT_ID.LEADER_BASE}
      data-testid={HOMEPAGE_GPT_ID.LEADER_BASE}
    />
  )
}
