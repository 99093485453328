import dynamic from 'next/dynamic'
import { useTranslation } from 'next-i18next'
import { useTheme } from 'styled-components'

import {
  AppMarketingBannerContainer,
  AppMarketingBannerImageContainer,
  AppMarketingBannerLinksContainer,
} from '@/components/homepage/app-marketing-banner/styled'
import { LinkCustom } from '@/components/shared/link-custom/LinkCustom'
import { ROUTE_LOCALE } from '@/domain/locale'
import { useLocale } from '@/hooks/useLocale'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { BodyText } from '@/ui/atoms/body-text'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { Spacing } from '@/ui/atoms/spacing'
import homepagePhoneImageEn from '~/public/next-assets/images/homepage/bg-phones-en.webp'
import homepagePhoneImageFr from '~/public/next-assets/images/homepage/bg-phones-fr.webp'

const AppStoreIcon = dynamic(() => import('@kijiji/icons/src/icons/BannerAppStore'), { ssr: false })
const AppStoreIconFr = dynamic(() => import('@kijiji/icons/src/icons/BannerAppStoreFr'), {
  ssr: false,
})
const GooglePlayIcon = dynamic(() => import('@kijiji/icons/src/icons/BannerGooglePlay'), {
  ssr: false,
})
const GooglePlayIconFr = dynamic(() => import('@kijiji/icons/src/icons/BannerGooglePlayFr'), {
  ssr: false,
})
const Image = dynamic(() => import('next/image'), { ssr: false })

const GOOGLE_PLAY_ICON_WIDTH = 150
const APP_STORE_ICON_WIDTH = 154

export const AppMarketingBanner = () => {
  const { colors, spacing } = useTheme()
  const { t } = useTranslation('home')
  const { routeLocale } = useLocale()

  const isFrench = routeLocale === ROUTE_LOCALE.fr

  const handleLinkClick = () => {
    trackEvent({ action: GA_EVENT.DownloadAppClick })
  }

  return (
    <AppMarketingBannerContainer data-testid="MarketingBanner">
      <Spacing mBottom={spacing.defaultSmall}>
        <HeadlineText as="h2" color={colors.white} size="large" weight="medium">
          {t('appMarketingBanner.title')}
        </HeadlineText>
      </Spacing>

      <BodyText color={colors.white} size="medium">
        {t('appMarketingBanner.subtitle')}
      </BodyText>

      <Spacing mTop={spacing.large}>
        <AppMarketingBannerLinksContainer flexWrap="wrap">
          <LinkCustom
            aria-label={t('appMarketingBanner.appStore.label')}
            href={t('appMarketingBanner.appStore.href')}
            onClick={handleLinkClick}
            rel="noopener noreferrer"
            target="_blank"
          >
            {isFrench ? (
              <AppStoreIconFr aria-hidden width={APP_STORE_ICON_WIDTH} />
            ) : (
              <AppStoreIcon aria-hidden width={APP_STORE_ICON_WIDTH} />
            )}
          </LinkCustom>

          <LinkCustom
            aria-label={t('appMarketingBanner.googlePlay.label')}
            href={t('appMarketingBanner.googlePlay.href')}
            onClick={handleLinkClick}
            rel="noopener noreferrer"
            target="_blank"
          >
            {isFrench ? (
              <GooglePlayIconFr aria-hidden width={GOOGLE_PLAY_ICON_WIDTH} />
            ) : (
              <GooglePlayIcon aria-hidden width={GOOGLE_PLAY_ICON_WIDTH} />
            )}
          </LinkCustom>

          <AppMarketingBannerImageContainer>
            <Image
              alt=""
              fill
              src={routeLocale ? homepagePhoneImageEn : homepagePhoneImageFr}
              unoptimized
            />
          </AppMarketingBannerImageContainer>
        </AppMarketingBannerLinksContainer>
      </Spacing>
    </AppMarketingBannerContainer>
  )
}
