import { type Locale } from '@kijiji/generated/graphql-types'
import { useTranslation } from 'next-i18next'
import { NextSeo } from 'next-seo'
import { useMemo } from 'react'

import { type GetSeoLocationResponse } from '@/components/homepage/useHomepageGetSeoUrl'
import { useLocale } from '@/hooks/useLocale'
import { getAbsoluteUrl, getBaseUrl } from '@/utils/url'

type HomepageSeoMetadataProps = {
  seoLocation: GetSeoLocationResponse
  locationId: number
  locationName: Locale
}

export const HomepageSeoMetadata = ({
  locationId,
  locationName,
  seoLocation,
}: HomepageSeoMetadataProps) => {
  const { apiLocale } = useLocale()
  const { t } = useTranslation('home')

  const title =
    locationId && locationName
      ? t('seo.meta.title.location', { location: locationName[apiLocale] })
      : t('seo.meta.title.default') // fallback for Canada or no location

  const description =
    locationId && locationName
      ? t('seo.meta.description.location', { location: locationName[apiLocale] })
      : t('seo.meta.description') // fallback for Canada or no location

  const baseUrl = useMemo(() => getBaseUrl(), [])
  const canonicalUrl =
    seoLocation.seoCurrentName === 'canada' && seoLocation.id === 0
      ? baseUrl
      : `${baseUrl}/h-${seoLocation.seoCurrentName}/${seoLocation.id}`

  return (
    <NextSeo
      title={title}
      description={description}
      canonical={canonicalUrl}
      titleTemplate={t('seo.meta.title.template')}
      openGraph={{
        title: 'Kijiji Canada',
        description: description,
        siteName: 'Kijiji',
        images: [
          {
            url: getAbsoluteUrl('/next-assets/images/fb_desktop.jpg'),
          },
        ],
        type: 'website',
      }}
    />
  )
}
